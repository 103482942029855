<script>
import { routerConfigs } from '@/router/configs';
import { getParentMenu } from '@/utils/router';
// 获取没有权限的人能看到的菜单~~
function getNoAuthRouter(routers) {
    if (!routers || !routers.length) return [];
    return routers
        .filter(item => item.meta.noAuth)
        .map(item => ({
            ...item,
            children: getNoAuthRouter(item.children),
        }));
}
// 获取普通用户（有部分服务权限 但不是管理员）的菜单
function getNotAdminRouter(routers) {
    if (!routers || !routers.length) return [];
    return routers
        .filter(item => !item.meta.admin)
        .map(item => ({
            ...item,
            children: getNotAdminRouter(item.children),
        }));
}

export default {
    name: 'KMenu',
    data() {
        return {
            opens: [],
        };
    },
    computed: {
        activeIndex() {
            let route = this.$route;
            while (route && !route.meta.showMenu) {
                route = getParentMenu(route);
            }
            return route?.path;
        },
        noAuthUser() {
            // 一个服务权限都没有的用户
            return this.$store.state.userInfo.authSerTypeList.length === 0;
        },
        isAdmin() {
            // 是否管理员
            return this.$store.state.userInfo.isAdmin;
        },
        routers() {
            if (this.noAuthUser) {
                return getNoAuthRouter(routerConfigs);
            }
            return this.isAdmin ? routerConfigs : getNotAdminRouter(routerConfigs);
        },
    },
    render(h) {
        function getMenu(configs) {
            return configs
                .filter(item => item.meta?.showMenu)
                .map(item => {
                    const children = item.children && item.children.filter(item => item.meta?.showMenu);
                    if (children?.length) {
                        const title = item.meta?.icon
                            ? [<i class={item.meta?.icon} style={{ color: item.meta?.iconColor, verticalAlign: 'text-bottom' }} />, item.meta?.title]
                            : [item.meta?.title];
                        return (
                            <el-submenu index={item.path} key={item.path}>
                                <span slot="title">{...title}</span>
                                {...getMenu(item.children)}
                            </el-submenu>
                        );
                    }
                    return (
                        <el-menu-item index={item.path} key={item.path}>
                            {item.meta?.title}
                        </el-menu-item>
                    );
                });
        }
        return (
            <el-menu default-active={this.activeIndex} class="k-menu" router>
                {...getMenu(this.routers)}
            </el-menu>
        );
    },
};
</script>

<style scoped>
.k-menu {
    height: 100%;
    overflow: auto;
}
</style>
