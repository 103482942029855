import { Message, Loading } from 'element-ui';
import { ApiCode } from '@/const/req';

export function getCookie(key) {
    let search = key + '=';
    let value = '';
    if (document.cookie.length > 0) {
        let offset = document.cookie.indexOf(search);
        if (offset !== -1) {
            offset += search.length;
            let end = document.cookie.indexOf(';', offset);
            if (end === -1) {
                end = document.cookie.length;
            }
            value = unescape(document.cookie.substring(offset, end));
        }
    }
    return value;
}
// url编码 和后端统一的规则 处理sso跳转失败的问题
export function encodeURL(url) {
    return url
        .replace(/\?/g, '_'.repeat(2))
        .replace(/=/g, '_'.repeat(3))
        .replace(/&/g, '_'.repeat(4));
}
// html 特殊字符转义
export function escapeHTML(str) {
    return !str
        ? ''
        : str.replace(
            /[&<>'"/]/g,
            tag =>
            ({
                '&': '&amp;',
                '<': '&lt;',
                '>': '&gt;',
                "'": '&#39;',
                '"': '&quot;',
                '/': '&#x2F;',
            }[tag] || tag)
        );
}
// 对象转数组
export function changeObjToArr(obj) {
    let arr = [];
    let keys = Object.keys(obj);
    for (let key of keys) {
        arr.push({ key, value: obj[key] });
    }
    return arr;
}
/**
 * 数组转对象
 * @param arr   {any[]}     被处理的数组
 * @param key   {string}    键字段
 * @param value {string}    值字段
 * @returns {{}}
 */
export function changeArrToObj(arr, key = 'key', value = 'value') {
    let obj = {};
    for (let item of arr) {
        obj[item[key]] = item[value];
    }
    return obj;
}

export function strToListSplitByEnter(str) {
    if (!str) return [];
    return str
        .split('\n')
        .map(item => item.trim())
        .filter(item => !!item);
}
/**
 * 从请求返回数据中获取返回结果
 * @param resPromise
 * @param options {Object}
 * @param options.showMsg {Boolean}     是否显示通知
 * @param options.callback {Function}    请求结束后的操作
 * @param options.errCallback {Function}    请求结束后的操作
 * @param options.msg {String}          显示通知的文案
 * @param options.finallyFn {Function}  始终执行的函数
 * @param options.showLoading {Boolean}  是否展示loading
 * @returns {Promise<*>}
 */
export async function getReqResult(resPromise, options = {}) {
    let loading;
    let response;
    try {
        const { showMsg, callback, msg = '操作成功', showLoading, loadingText = 'Loading' } = options;
        if (showLoading) {
            loading = Loading.service({
                lock: true,
                text: loadingText,
                spinner: 'el-icon-loading',
                background: 'rgba(0, 0, 0, 0.7)',
            });
        }
        response = await resPromise;
        const { data, code } = response;
        if (code === ApiCode.NO_ERROR) {
            callback && callback(data);
            showMsg && Message.success(msg);
            return data;
        }
    } finally {
        loading?.close();
        options.finallyFn && options.finallyFn();
    }
    return response;
}
/**
 * 根据多级路径 获取对象属性 如果不存在对应属性会返回 undefined
 * @param obj   {Object} 操作的对象
 * @param path  {Array}  属性路径的数组
 * @returns {undefined|*}
 */
export function deepGet(obj, path) {
    let length = path.length;
    for (let i = 0; i < length; i++) {
        if (obj == null) return undefined;
        obj = obj[path[i]];
    }
    return length ? obj : undefined;
}
// 格式化JSON
export function formatJson(json, space = 4) {
    if (!json) return json;
    try {
        return JSON.stringify(JSON.parse(json), null, space);
    } catch (e) {
        console.log('json格式错误', json);
        return json;
    }
}
// 数组转map
