<template>
    <div class="k-layout">
        <Header />
        <section class="k-content">
            <aside class="k-aside">
                <Menu />
            </aside>
            <div class="k-main">
                <PageInfo />
                <div class="k-page-content">
                    <div class="k-page-view">
                        <keep-alive>
                            <router-view v-if="$route.meta.keepAlive">
                                <!-- 这里是会被缓存的视图组件，比如 Home！ -->
                            </router-view>
                        </keep-alive>

                        <router-view v-if="!$route.meta.keepAlive">
                            <!-- 这里是不被缓存的视图组件，比如 Edit！ -->
                        </router-view>
                    </div>
                    <Footer />
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Header from './Header';
import Menu from './Menu';
import Footer from './Footer';
import PageInfo from './PageInfo';

export default {
    name: 'Layout',
    components: {
        Header,
        Menu,
        Footer,
        PageInfo,
    },
};
</script>

<style scoped>
.k-layout {
    padding-top: 60px;
}
.k-content {
    padding-left: 250px;
}
.k-aside {
    width: 250px;
    position: fixed;
    left: 0;
    top: 60px;
    bottom: 0;
}
.k-main {
    height: calc(100vh - 60px);
    padding-top: 100px;
}
.k-page-content {
    height: calc(100vh - 60px - 100px);
    overflow: auto;
}
.k-page-view {
    padding: 20px;
    min-height: calc(100vh - 60px - 100px - 70px);
}
</style>
