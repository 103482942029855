import { musicRouters } from "./music";
import { toningRouters } from "./toning";
import { miscRouters } from "./misc";
export const routerConfigs = [
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/home"),
    meta: {
      title: "首页", // 标题
      // showMenu: true,                  // 是否展示在菜单
      // icon: 'el-icon-data-line',       // 菜单中的icon
      // iconColor: '#e06767',            // 菜单中icon颜色
      hiddenBreadcrumb: true, // 是否不显示在面包屑 默认显示
      // parentMenu: 'templateManage',    // 显示面包屑时 父菜单的名字
      // notClick: false,                 // 不可以在面包屑中点击 默认false
      // params: { srcField: distField }  // 面包屑跳转需要传参数 源页面route.query[srcField]传到跳转页面源页面route.query[distField]
      // keepAlive: true                  // 是否 keep-alive 缓存
      // noAuth: true,                    // 菜单权限：没有服务也可以看到
      // admin: true,                     // 菜单权限：只有管理员可见
    },
  },

  musicRouters,
  toningRouters,
  miscRouters,

  {
    path: "*",
    name: "others_page/not_found",
    component: () => import("@/views/others_page/not_found.vue"),
    meta: {
      title: "404",
    },
  },
];
