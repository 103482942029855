<template>
    <div class="page-info">
        <el-breadcrumb separator="/">
            <el-breadcrumb-item v-for="item in paths" :key="item.path" :to="getPath(item, paths)">
                {{ item.meta.title }}
            </el-breadcrumb-item>
        </el-breadcrumb>

        <div class="page-title">{{ $route.meta.title }}</div>
    </div>
</template>

<script>
import { getParentMenu } from '@/utils/router';

export default {
    name: 'PageInfo',
    data() {
        return {};
    },
    computed: {
        route() {
            return this.$route;
        },
        paths() {
            let result = [];
            let route = this.$route;
            while (route !== null) {
                if (!route.meta.hiddenBreadcrumb) {
                    result.push(route);
                }
                route = getParentMenu(route);
            }
            return result.reverse();
        },
    },
    methods: {
        getPath(item, paths) {
            let isLast = item === paths[paths.length - 1];
            if (isLast || item.meta.notClick) {
                return null;
            }
            let query = {},
                params = this.$route.meta.params;
            if (params) {
                Object.keys(params).forEach(key => {
                    query[params[key]] = this.$route.query[key];
                });
            }
            return {
                path: item.redirect || item.path,
                query,
            };
        },
    },
};
</script>

<style scoped>
.page-info {
    height: 100px;
    background: #ffffff;
    border-bottom: 1px solid #e6e6e6;
    position: fixed;
    top: 60px;
    left: 250px;
    right: 0;
    z-index: 100;
    padding: 20px;
}
.page-title {
    color: #333;
    margin-top: 22px;
}
</style>
