<template>
    <footer class="k-footer">
        <div>TGMAN</div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
};
</script>

<style scoped>
.k-footer {
    height: 70px;
    text-align: center;
    color: rgba(0, 0, 0, 0.4);
    font-size: 13px;
}
.help-link {
    margin-bottom: 10px;
}
</style>
