import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './styles/index.css';
import axios from 'axios';

Vue.prototype.$axios = axios;

Vue.config.productionTip = false;
Vue.use(ElementUI, { size: 'small' });

export const instance = new Vue({
    router,
    store,
    render: h => h(App),
}).$mount('#app');
