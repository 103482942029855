// 查询所有下拉框的值
export function _selectedList() {
    return Promise.resolve({
        data: {
            code: 0,
            message: '成功',
            data: {
                stateList: [
                    {
                        code: 'on',
                        desc: '可用',
                    },
                    {
                        code: 'repairing',
                        desc: '维修中',
                    },
                    {
                        code: 'init',
                        desc: '等待初始化',
                    },
                    {
                        code: 'no_am',
                        desc: '等待安装am',
                    },
                    {
                        code: 'ping_error',
                        desc: 'Ping不通',
                    },
                    {
                        code: 'am_lost',
                        desc: 'am失联',
                    },
                    {
                        code: 'agent_lost',
                        desc: 'agent失联',
                    },
                ],
                fileTypeList: [
                    {
                        code: 'PACKAGE',
                        desc: '安装包',
                    },
                    {
                        code: 'SCRIPT',
                        desc: '脚本',
                    },
                    {
                        code: 'PROCESS_ZIP',
                        desc: '流程的压缩包',
                    },
                ],
                topologyKeyList: [
                    {
                        code: 'service',
                        desc: '服务类型',
                    },
                    {
                        code: 'component',
                        desc: '组件',
                    },
                    {
                        code: 'country',
                        desc: '国家',
                    },
                    {
                        code: 'region',
                        desc: '地区',
                    },
                    {
                        code: 'host_az',
                        desc: 'az',
                    },
                    {
                        code: 'host_idc',
                        desc: 'idc',
                    },
                    {
                        code: 'host_model',
                        desc: '型号',
                    },
                    {
                        code: 'rack_info',
                        desc: '机架',
                    },
                ],
                topologyRuleOperatorList: [
                    {
                        code: 'EQUAL',
                        desc: '等于',
                    },
                    {
                        code: 'NOT_EQUAL',
                        desc: '不等于',
                    },
                    {
                        code: 'CONTAIN',
                        desc: '包含',
                    },
                    {
                        code: 'NOT_CONTAIN',
                        desc: '不包含',
                    },
                    {
                        code: 'NOT_NULL',
                        desc: '不为空',
                    },
                ],
                hostChoseTypeList: [
                    {
                        code: 'CONFIG_GROUP',
                        desc: '配置组',
                    },
                    {
                        code: 'HOST_LIST',
                        desc: '机器列表',
                    },
                ],
                procTypeList: [
                    {
                        code: 'INSTALL',
                        desc: '部署',
                    },
                    {
                        code: 'UPGRADE',
                        desc: '升级',
                    },
                    {
                        code: 'EXPANSION',
                        desc: '扩容',
                    },
                    {
                        code: 'RESTART',
                        desc: '重启',
                    },
                    {
                        code: 'NO_APPROVE_UPGRADE',
                        desc: '非审批操作',
                    },
                    {
                        code: 'AUTO_INSTALL',
                        desc: '自动拉起服务',
                    },
                    {
                        code: 'HDFS_UPGRADE',
                        desc: 'HDFS升级',
                    },
                    {
                        code: 'KAFKA_STANDBY_REPLACE',
                        desc: 'kafka备机替换',
                    },
                ],
                tplStatusList: [
                    {
                        code: 'ONLINE',
                        desc: '上线',
                    },
                    {
                        code: 'OFFLINE',
                        desc: '废弃',
                    },
                    {
                        code: 'UNKNOWN',
                        desc: '未知的异常情况',
                    },
                ],
                tplTypeList: [
                    {
                        code: 'BASE_TEMPLATE',
                        desc: '普通模板',
                    },
                    {
                        code: 'COMPOSITE_TEMPLATE',
                        desc: '组合模板',
                    },
                ],
                scriptTypeList: [
                    {
                        code: 'PYTHON',
                        desc: 'python脚本',
                    },
                    {
                        code: 'SHELL',
                        desc: 'shell脚本',
                    },
                    {
                        code: 'OTHER',
                        desc: '其他脚本',
                    },
                ],
                scriptUseTypeList: [
                    {
                        code: 'PUBLIC',
                        desc: '公共脚本插件',
                    },
                    {
                        code: 'COMMON',
                        desc: '普通脚本插件',
                    },
                    {
                        code: 'ALERT_PUBLIC',
                        desc: '监控公共脚本',
                    },
                    {
                        code: 'ALERT_COMMON',
                        desc: '监控普通脚本',
                    },
                ],
                procScriptUseTypeList: [
                    {
                        code: 'PUBLIC',
                        desc: '公共脚本插件',
                    },
                    {
                        code: 'COMMON',
                        desc: '普通脚本插件',
                    },
                ],
                alertScriptUseTypeList: [
                    {
                        code: 'ALERT_PUBLIC',
                        desc: '监控公共脚本',
                    },
                    {
                        code: 'ALERT_COMMON',
                        desc: '监控普通脚本',
                    },
                ],
                approveStateList: [
                    {
                        code: 'WAIT_APPROVE',
                        desc: '等待审批',
                    },
                    {
                        code: 'APPROVED',
                        desc: '审批通过',
                    },
                    {
                        code: 'REJECT',
                        desc: '被驳回',
                    },
                ],
                insStatusList: [
                    {
                        code: 'DONE',
                        desc: '结束',
                    },
                    {
                        code: 'NOT_DONE',
                        desc: '未结束',
                    },
                ],
                hostProcessStatusList: [
                    {
                        code: 'NOT_DONE',
                        desc: '执行未完成',
                    },
                    {
                        code: 'SUCCESS',
                        desc: '成功',
                    },
                    {
                        code: 'FAIL',
                        desc: '失败',
                    },
                ],
                processStatusList: [
                    {
                        code: 'NOT_EXECUTE',
                        desc: '未执行',
                    },
                    {
                        code: 'EXECUTING',
                        desc: '执行中',
                    },
                    {
                        code: 'PAUSE',
                        desc: '流程暂停',
                    },
                    {
                        code: 'SUCCESS',
                        desc: '成功',
                    },
                    {
                        code: 'PART_FAIL',
                        desc: '部分失败',
                    },
                    {
                        code: 'FAIL',
                        desc: '失败',
                    },
                    {
                        code: 'PART_EXECUTING',
                        desc: '灰度执行中',
                    },
                    {
                        code: 'PART_PAUSE',
                        desc: '灰度暂停',
                    },
                    {
                        code: 'DONE',
                        desc: '结束',
                    },
                ],
                stepStatusList: [
                    {
                        code: 'NOT_EXECUTE',
                        desc: '未执行',
                    },
                    {
                        code: 'WAIT_EXECUTE',
                        desc: '等待执行',
                    },
                    {
                        code: 'EXECUTING',
                        desc: '执行中',
                    },
                    {
                        code: 'PAUSE',
                        desc: '步骤暂停',
                    },
                    {
                        code: 'SUCCESS',
                        desc: '成功',
                    },
                    {
                        code: 'PART_FAIL',
                        desc: '部分失败',
                    },
                    {
                        code: 'FAIL',
                        desc: '失败',
                    },
                ],
                alertMetricTplTypeList: [
                    {
                        code: 'GATHER',
                        desc: '聚合模板',
                    },
                    {
                        code: 'HOST',
                        desc: '单机模板',
                    },
                ],
                alertMetricRuleLevelList: [
                    {
                        code: 'P0',
                        desc: 'P0:电话、kim（重要且紧急报警，立即处理）',
                    },
                    {
                        code: 'P1',
                        desc: 'P1:kim(一般紧急报警，半小时处理)',
                    },
                    {
                        code: 'P2',
                        desc: 'P2:kim(不紧急报警，需要治理)',
                    },
                ],
                alertMetricComputeTypeList: [
                    {
                        code: 'SINGLE_METRIC',
                        desc: '单机指标计算',
                    },
                    {
                        code: 'HOST_COUNT',
                        desc: '机器数计算',
                    },
                    {
                        code: 'METRIC_GATHER',
                        desc: '指标聚合计算',
                    },
                ],
                alertScheduleStatusList: [
                    {
                        code: 'ON',
                        desc: '已开启',
                    },
                    {
                        code: 'OFF',
                        desc: '已关闭',
                    },
                ],
                alertClusterMatchTypeList: [
                    {
                        code: 'EXACT',
                        desc: '精确字符串匹配',
                    },
                    {
                        code: 'REGULAR',
                        desc: '正则表达式匹配',
                    },
                ],
                alertScheduleProcessTypeList: [
                    {
                        code: 'INSTALL',
                        desc: '自动部署监控',
                    },
                    {
                        code: 'STOP_DELETE',
                        desc: '停用、删除监控',
                    },
                    {
                        code: 'HOST_UPGRADE',
                        desc: '机器监控灰度升级',
                    },
                    {
                        code: 'VERSION_UPGRADE',
                        desc: '监控版本升级',
                    },
                    {
                        code: 'ALL_PUBLIC_UPGRADE',
                        desc: '通用脚本全量升级',
                    },
                    {
                        code: 'HOST_PUBLIC_UPGRADE',
                        desc: '通用脚本灰度升级',
                    },
                ],
                alertScheduleProcessStatusList: [
                    {
                        code: 'RETRY_NOT_EXECUTE',
                        desc: '重试未执行',
                    },
                    {
                        code: 'NOT_EXECUTE',
                        desc: '未执行',
                    },
                    {
                        code: 'EXECUTING',
                        desc: '执行中',
                    },
                    {
                        code: 'SUCCESS',
                        desc: '成功',
                    },
                    {
                        code: 'FAIL',
                        desc: '失败',
                    },
                    {
                        code: 'PART_FAIL',
                        desc: '部分失败',
                    },
                ],
                alertScheduleReportStatusList: [
                    {
                        code: 'DEFAULT',
                        desc: '默认',
                    },
                    {
                        code: 'SUCCESS',
                        desc: '成功',
                    },
                    {
                        code: 'FAILED',
                        desc: '失败',
                    },
                    {
                        code: 'ABORTED',
                        desc: '中止',
                    },
                ],
                orderWindowTypeList: [
                    {
                        code: 'BY_WEEK',
                        desc: '按周分配',
                    },
                    {
                        code: 'BY_DAY',
                        desc: '按天分配',
                    },
                ],
                orderWindowRuleByWeekList: [
                    {
                        code: 'FIRST_WEEK',
                        desc: '第一周',
                    },
                    {
                        code: 'SECOND_WEEK',
                        desc: '第二周',
                    },
                    {
                        code: 'THIRD_WEEK',
                        desc: '第三周',
                    },
                    {
                        code: 'FOURTH_WEEK',
                        desc: '第四周',
                    },
                ],
                orderWindowRuleByDayList: [
                    {
                        code: 'MONDAY',
                        desc: '周一',
                    },
                    {
                        code: 'TUESDAY',
                        desc: '周二',
                    },
                    {
                        code: 'WEDNESDAY',
                        desc: '周三',
                    },
                    {
                        code: 'THURSDAY',
                        desc: '周四',
                    },
                    {
                        code: 'FRIDAY',
                        desc: '周五',
                    },
                    {
                        code: 'SATURDAY',
                        desc: '周六',
                    },
                    {
                        code: 'SUNDAY',
                        desc: '周日',
                    },
                ],
                orderTypeList: [
                    {
                        code: 'DEPLOY',
                        desc: '部署',
                    },
                    {
                        code: 'UPGRADE',
                        desc: '升级',
                    },
                    {
                        code: 'EXPAND',
                        desc: '扩容',
                    },
                    {
                        code: 'ADJUST_CONFIG',
                        desc: '调整配置',
                    },
                    {
                        code: 'ADD_MONITOR',
                        desc: '新增监控',
                    },
                    {
                        code: 'ADJUST_MONITOR',
                        desc: '调整监控',
                    },
                    {
                        code: 'SOFTWARE_TEST',
                        desc: '软件测试',
                    },
                    {
                        code: 'HARDWARE_TEST',
                        desc: '硬件测试',
                    },
                    {
                        code: 'DATA_TRANSFER',
                        desc: '数据迁移',
                    },
                    {
                        code: 'CHECK_PROBLEM',
                        desc: '排查问题',
                    },
                    {
                        code: 'SERVICE_OFFLINE',
                        desc: '服务下线',
                    },
                    {
                        code: 'MACHINE_OFFLINE',
                        desc: '机器下线',
                    },
                    {
                        code: 'OTHERS',
                        desc: '其他',
                    },
                ],
                orderPriorityList: [
                    {
                        code: 'P0',
                        desc: '非常紧急',
                    },
                    {
                        code: 'P1',
                        desc: '紧急',
                    },
                    {
                        code: 'P2',
                        desc: '一般',
                    },
                    {
                        code: 'P3',
                        desc: '不紧急',
                    },
                ],
                orderStateList: [
                    {
                        code: 'START_ORDER',
                        desc: '工单发起',
                    },
                    {
                        code: 'COMPLETE_ORDER',
                        desc: '完善工单',
                    },
                    {
                        code: 'ADJUST_CONFIRM',
                        desc: '调整确认',
                    },
                    {
                        code: 'DISTRIBUTE_TASK',
                        desc: '分配任务',
                    },
                    {
                        code: 'WINDOW_CONFIRM',
                        desc: '窗口期确认',
                    },
                    {
                        code: 'EXECUTE_TASK',
                        desc: '执行任务',
                    },
                    {
                        code: 'FINISH',
                        desc: '完成',
                    },
                    {
                        code: 'PAUSE',
                        desc: '暂停',
                    },
                    {
                        code: 'CLOSE',
                        desc: '关闭',
                    },
                ],
                hdfsTypeList: [
                    {
                        code: 'HDFS_UPGRADE_SINGLE_RACK',
                        desc: 'HDFS 单机架升级',
                    },
                    {
                        code: 'HDFS_UPGRADE_MULTI_RACK',
                        desc: 'HDFS 多机架升级',
                    },
                ],
                hdfsStepList: [
                    {
                        code: 'HDFS_ENTER',
                        desc: '进入enter池',
                    },
                    {
                        code: 'HDFS_MOVING',
                        desc: '进入move池',
                    },
                    {
                        code: 'HDFS_IN',
                        desc: '进入in池',
                    },
                    {
                        code: 'UPGRADE',
                        desc: '升级',
                    },
                    {
                        code: 'DONE',
                        desc: '步骤结束',
                    },
                ],
                kasProductList: [
                    {
                        code: 'hdfs',
                        desc: 'HDFS',
                    },
                    {
                        code: 'compute_offline',
                        desc: '离线计算',
                    },
                    {
                        code: 'blobstore',
                        desc: 'Blobstore',
                    },
                    {
                        code: 'compute_online',
                        desc: '实时计算',
                    },
                    {
                        code: 'kafka',
                        desc: 'Kafka',
                    },
                    {
                        code: 'ch',
                        desc: 'OLAP（CK）',
                    },
                    {
                        code: 'druid',
                        desc: 'OLAP（Druid）',
                    },
                    {
                        code: 'hbase',
                        desc: 'HBase',
                    },
                ],
                kasSkuBudgetStatusList: [
                    {
                        code: 'WAIT_DELIVERY',
                        desc: '待交付',
                    },
                    {
                        code: 'DELIVERY_ING',
                        desc: '交付中',
                    },
                    {
                        code: 'DONE',
                        desc: '已交付',
                    },
                ],
                kasHostBudgetStatusList: [
                    {
                        code: 'NOT_SUBMIT',
                        desc: '未提交',
                    },
                    {
                        code: 'WAIT_DELIVERY',
                        desc: '待交付',
                    },
                    {
                        code: 'WAIT_EXPANSION',
                        desc: '已交付,待SRE扩容',
                    },
                    {
                        code: 'DONE',
                        desc: '已扩容',
                    },
                ],
                kasForecastTypeList: [
                    {
                        code: 'FORECAST',
                        desc: '预算内',
                    },
                    {
                        code: 'NOT_FORECAST',
                        desc: '预算外',
                    },
                ],
                clusterBuildStatusList: [
                    {
                        code: 'DONE',
                        desc: '结束',
                    },
                    {
                        code: 'NOT_DONE',
                        desc: '未结束',
                    },
                ],
                multiInsTaskStatusList: [
                    {
                        code: 'DONE',
                        desc: '结束',
                    },
                    {
                        code: 'NOT_DONE',
                        desc: '未结束',
                    },
                ],
                offlineProcessStatusList: [
                    {
                        code: 'SUBMIT_PROCESS',
                        desc: '流程单发起，待业务负责人审批',
                    },
                    {
                        code: 'BUSINESS_APPROVAL_OK',
                        desc: '业务负责人审批通过，待sre负责人审批',
                    },
                    {
                        code: 'BUSINESS_APPROVAL_NO',
                        desc: '业务负责人审批拒绝',
                    },
                    {
                        code: 'SRE_APPROVAL_OK',
                        desc: 'SRE负责人审批通过，待提单人开始执行流程',
                    },
                    {
                        code: 'SRE_APPROVAL_NO',
                        desc: 'SRE负责人审批拒绝',
                    },
                    {
                        code: 'START',
                        desc: '提单人开始执行',
                    },
                    {
                        code: 'END',
                        desc: '提单人结束执行并填写观测结果，待结果确认人审批',
                    },
                    {
                        code: 'OK',
                        desc: '完成',
                    },
                    {
                        code: 'CHECK_APPROVAL_NO',
                        desc: '结果确认人审批拒绝',
                    },
                ],
                offlineProcessStepList: [
                    {
                        code: 'SUBMIT_PROCESS',
                        desc: '提交流程单',
                    },
                    {
                        code: 'BUSINESS_APPROVAL',
                        desc: '业务负责人审批',
                    },
                    {
                        code: 'SRE_APPROVAL',
                        desc: 'SRE负责人审批',
                    },
                    {
                        code: 'START',
                        desc: '开始执行',
                    },
                    {
                        code: 'END',
                        desc: '结束执行',
                    },
                    {
                        code: 'CHECK_APPROVAL',
                        desc: '结果观测人审批',
                    },
                ],
                offlineProcessTypeList: [
                    {
                        code: 'INSTALL',
                        desc: '部署',
                    },
                    {
                        code: 'UPGRADE',
                        desc: '升级',
                    },
                    {
                        code: 'EXPANSION',
                        desc: '扩容',
                    },
                    {
                        code: 'RESTART',
                        desc: '重启',
                    },
                    {
                        code: 'AUTO_INSTALL',
                        desc: '自动拉起服务',
                    },
                    {
                        code: 'HDFS_UPGRADE',
                        desc: 'HDFS升级',
                    },
                    {
                        code: 'OTHER_TYPE',
                        desc: '其他',
                    },
                ],
                avertApprStatusList: [
                    {
                        code: 'ON',
                        desc: '开启',
                    },
                    {
                        code: 'OFF',
                        desc: '关闭',
                    },
                ],
                insApprStageList: [
                    {
                        code: 'FILL_PROC_INFO',
                        desc: '填写流程信息',
                    },
                    {
                        code: 'SEVER_USER_APPR',
                        desc: '业务负责人审批',
                    },
                    {
                        code: 'SRE_USER_APPR',
                        desc: 'sre负责人审批',
                    },
                    {
                        code: 'SP_USER_APPR',
                        desc: '特殊负责人审批',
                    },
                    {
                        code: 'PROC_EXECUTE',
                        desc: '执行流程',
                    },
                    {
                        code: 'FILL_WATCH_RESULT',
                        desc: '填写观测结果',
                    },
                    {
                        code: 'WATCH_RESULT_CONFIRM',
                        desc: '观测结果确认',
                    },
                    {
                        code: 'COMPLETE',
                        desc: '完成',
                    },
                ],
                insApprStateList: [
                    {
                        code: 'NOT_APPROVE',
                        desc: '未审批',
                    },
                    {
                        code: 'WAIT_APPROVE',
                        desc: '待审批',
                    },
                    {
                        code: 'APPROVED',
                        desc: '审批通过',
                    },
                    {
                        code: 'REJECT',
                        desc: '被驳回',
                    },
                    {
                        code: 'NOT_COMPLETED',
                        desc: '审批未完成',
                    },
                ],
                insApprStates4Query: [
                    {
                        code: 'APPROVED',
                        desc: '审批通过',
                    },
                    {
                        code: 'REJECT',
                        desc: '被驳回',
                    },
                    {
                        code: 'NOT_COMPLETED',
                        desc: '审批未完成',
                    },
                ],
                qtBrokerCheckStateList: [
                    {
                        code: 'OK',
                        desc: '列表正常',
                    },
                    {
                        code: 'EXCEPTION',
                        desc: '列表异常',
                    },
                    {
                        code: 'ERROR',
                        desc: '请求失败',
                    },
                ],
                insExeStatusList: [
                    {
                        code: 'SUCCESS',
                        desc: '成功',
                    },
                    {
                        code: 'NOT_SUCCESS',
                        desc: '非成功',
                    },
                ],
                onlineQuotaChangeTaskStatusList: [
                    {
                        code: 'NOT_APPROVE',
                        desc: '未审批',
                    },
                    {
                        code: 'REFUSED',
                        desc: '审批拒绝',
                    },
                    {
                        code: 'APPROVED',
                        desc: '审批通过',
                    },
                    {
                        code: 'WAIT_EXECUTING',
                        desc: '等待执行',
                    },
                    {
                        code: 'EXECUTING',
                        desc: '执行中',
                    },
                    {
                        code: 'DONE',
                        desc: '结束',
                    },
                ],
            },
        },
        code: 0,
    });
    // return request.get('/api/selectedList');
}
// 获取当前登录用户
export function _getCurrentUser() {
    return fetch(`/admin-curr-user`)
        .then(d => {
            return d.json();
        })
        .then(d => {
            if (!d.data.userName  && location.pathname !== "/") {
                  location.href = '/';
            }
            return d;
        });
}
// 查询用户列表
export function _getUserList(username) {
    return Promise.resolve({ data: {}, code: 0 });
    //   return request.get("/user/getUserList", {
    //     params: {
    //       username,
    //     },
    //   });
}
