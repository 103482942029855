import { routes as allRouters } from '@/router';

export function getMenuByName(routeName, routes = allRouters) {
    let result;
    for (let route of routes) {
        if (route.name === routeName) {
            return route;
        }
        if (route.children?.length && (result = getMenuByName(routeName, route.children)) !== null) {
            return result;
        }
    }
    return null;
}

export function getParentMenu(current, routes = allRouters) {
    if (current.path === '/') {
        return null;
    }
    if (current.meta.parentMenu) {
        return getMenuByName(current.meta.parentMenu);
    }
    let result;
    for (let route of routes) {
        if (route.children && route.children.find(child => child.name === current.name)) {
            return route
        }
        if (route.children?.length && (result = getParentMenu(current, route.children)) !== null) {
            return result;
        }
    }
    return null;
}
