export const miscRouters = {
  path: "/admin/misc",
  name: "adminMisc",
  component: () => import("@/views/music/misc"),
  meta: {
    title: "综合静态配置",
    showMenu: true,
    icon: "el-icon-s-tools",
    iconColor: "#f06b24",
    notClick: true,
    // admin: true,
  },
  children: [
    {
      path: "/admin/misc/avatar",
      name: "adminAvatar",
      component: () => import("@/views/music/misc/avatar.vue"),
      meta: {
        title: "全局配置",
        showMenu: true,
      },
    },
  ],
};
