<template>
    <div id="app">
        <router-view></router-view>
    </div>
</template>

<script>
export default {
    name: 'App',
    created() {
        // this.$store.dispatch('getServiceType');
        this.$store.dispatch('selectedList');
        this.$store.dispatch('getCurrentUser');
        // this.$store.dispatch('getAllRoleMap');

        window.EF_FEEDBACK = {
            // 为问题反馈系统提供获取用户信息API
            getUserId: () => {
                return { userId: this.$store.state.userInfo.username };
            },
        };
    },
};
</script>

<style lang="scss" scoped>
#app {
    height: 100%;
}
</style>
