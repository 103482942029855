export const musicRouters = {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/music/piano-room'),
    meta: {
        title: '琴房',
        showMenu: true,
        icon: 'el-icon-files',
        iconColor: '#f06b24',
        notClick: true,
        // admin: true,
    },
    children: [
        {
            path: '/admin/music',
            name: 'skuBudget',
            component: () => import('@/views/music/piano-room/music.vue'),
            meta: {
                title: '曲目管理',
                showMenu: true,
            },
        },
        {
            path: '/admin/setClamp',
            name: 'skuBudget',
            component: () => import('@/views/music/piano-room/setClamp.vue'),
            meta: {
                title: '合集管理',
                showMenu: true,
            },
        },
    ],
};
