import Vue from 'vue'
import Vuex from 'vuex'
 
 
import { _getCurrentUser, _selectedList } from '@/api/api';
import { getReqResult } from '@/utils';
 
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        serviceTypeList: [],
        selectMap: {
            fileTypeList: [],
            topologyKeyList: [],
            topologyRuleOperatorList: [],
            scriptTypeList: [],
            procScriptUseTypeList: [],
            procTypeList: [],       // 操作类型
            tplTypeList: [],        // 模板类型
            tplStatusList: [],      // 模板状态
            alertScriptUseTypeList: [], // 监控脚本用途类型
            alertMetricTplTypeList: [], // 监控模板类型
            alertScheduleStatusList: [], // 报警开关状态
            alertMetricRuleLevelList: [], // 监控模板报警级别
            alertMetricComputeTypeList: [], // 监控规则计算方式
            alertScheduleProcessStatusList: [], // 监控任务状态
            alertScheduleProcessTypeList: [], // 监控任务类型
            alertScheduleReportStatusList: [], // 脚本调度 监控运行状态
            hdfsTypeList: [], // HDFS机架类型
            orderPriorityList: [], // HDFS机架类型
            kasProductList: [], // kas产品类型
            kasSkuBudgetStatusList: [], // kas sku预算状态
            kasHostBudgetStatusList: [], // kas host预算状态
            offlineProcessStatusList: [], // 下线流程状态
        },
        userInfo: {
            username: '',
            userNameCn: '',
            bpAdmin: false,
            bpOperator: false,
            sysAdmin: false,
            isAdmin: false,
            authSerTypeList: [],
            mngSerTypeList: [],
            defaultSerType: '',
        },
        allRoleMap: { // 工单系统角色权限
            BP_ADMIN: [],
            BP_INTERFACE: [],
            BP_OPERATOR: [],
            BUSINESS_PROXY: [],
            BUSINESS_MANAGER: [],
            SYSTEM_ADMIN: [],
        },
    },
    mutations: {
        SET_SERVICE_TYPE_LIST(state, serviceTypeList) {
            state.serviceTypeList = serviceTypeList;
        },
        SET_SELECT_MAP(state, selectMap) {
            state.selectMap = selectMap
        },
        SET_USERINFO(state, userInfo) {
            const { userName, userNameCn, bpAdmin, bpOperator, sysAdmin, authSerTypeList, mngSerTypeList } = userInfo;
            const isAdmin = bpAdmin || bpOperator || sysAdmin;
            state.userInfo = {
                username: userName,
                userNameCn: userNameCn,
                bpAdmin: bpAdmin,
                bpOperator: bpOperator,
                sysAdmin: sysAdmin,
                isAdmin: isAdmin,
                authSerTypeList: authSerTypeList,
                mngSerTypeList: mngSerTypeList,
                defaultSerType: bpOperator ? mngSerTypeList[0] : (isAdmin ? undefined : authSerTypeList[0]),
            };
        },
        SET_ALL_ROLE_MAP(state, allRoleMap) {
            state.allRoleMap = allRoleMap
        }
    },
    actions: {
       
        selectedList({ commit }) {
            getReqResult(_selectedList(), {
                callback: (data) => {
                    commit('SET_SELECT_MAP', data)
                }
            })
        },
        getCurrentUser({ commit }) {
            return getReqResult(_getCurrentUser(), {
                callback: (data) => {
                    commit('SET_USERINFO', data);
                }
            });
        },
    
    },
    modules: {
       
    }
})
