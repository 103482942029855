<template>
    <header class="k-header">
        <div class="k-logo">
            <img src="../../assets/logo.png" class="k-logo-img" />
            <div class="k-logo-text">
                <div class="k-logo-title">音乐APP</div>
                <div class="k-logo-subtitle">后台管理</div>
            </div>
        </div>

        <ul class="platform-list"></ul>

        <div class="username-info">
            {{ username }} --
            <a href="#" @click="logout">[登出]</a>
        </div>
        <div class="avatar">
            <img src="../../assets/avatar.png" alt="用户头像" class="avatar-img" />
        </div>
    </header>
</template>

<script>
export default {
    name: 'Header',
    computed: {
        otherHost() {
            return '';
        },
        username() {
            return this.$store.state.userInfo.username;
        },
    },
    methods: {
        logout() {
            fetch(`/admin-logout`).then(d => {
                location.href = '/';
            });
        },
    },
};
</script>

<style scoped>
.k-header {
    background: #409eff;
    color: #fff;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
    box-shadow: 1px 1px 2px 1px #00000029;
    z-index: 1000;
    display: flex;
}
.k-logo {
    display: flex;
    align-items: center;
    height: 100%;
    width: 250px;
    justify-content: center;
    cursor: pointer;
}
.k-logo-img {
    font-size: 36px;
    margin-right: 15px;
    width: 50px;
    background: #4e423ed1;
    border-radius: 50%;
}
.k-logo-text {
    display: flex;
    flex-direction: column;
}
.k-logo-title {
    font-size: 16px;
    font-weight: 900;
    text-shadow: 2px 1px cornflowerblue;
}
.k-logo-subtitle {
    font-size: 12px;
    color: #d5dcf2;
}
.platform-list {
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
}
.platform-item {
    flex-grow: 0;
    flex-shrink: 0;
    padding: 0 20px;
    line-height: 62px;
    text-align: center;
    border-bottom: 2px solid rgba(0, 0, 0, 0);
    font-size: 16px;
    height: 58px;
}
.platform-item.active {
    color: #ffd500;
    border-bottom: 2px solid #ffd500;
}
.platform-item > a:hover {
    height: 100%;
    display: inline-block;
    color: #ffd500;
}
.avatar {
    padding: 12px;
    flex: 100px 0 0;
}
.avatar-img {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    flex: 100px 0 0;
}
.username-info {
    line-height: 60px;
}
</style>
