import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout";
import { routerConfigs } from "./configs";
import routerGuard from "./routerGuard";

Vue.use(VueRouter);

// 解决 vue-router 控制台报错 NavigationDuplicated 的问题
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch((err) => err);
};

export const routes = [
  {
    path: "/",
    component: Layout,
    children: routerConfigs,
    meta: {
      title: "首页",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

routerGuard(router);

export default router;
